import React from 'react'

const Work = () => {

  return (
    <div className='work'>
      <p style={{textAlign:'center'}} >For Order fulfillment, we will pick & pack orders in a wide variety of packaging depending on your needs</p>
      <p style={{textAlign:'center'}}>For Warehousing, we will recieive your inventory, inspect, verify & stock pending distribution</p>
      <p>For Shipping & Logistics, whether a single carton or multiple pallet loads, we can have product in your clients hands at any time you require. We ship daily with all common carriers as well as freight & air frieght companies. This allows us to offer cost comparisons to ensure the most cost effective mode of transportation.</p>

      <p>For Kitting & Hand Assembly, we offer custom assembly of oversized material, collation of elements, folding and wrapping as well as dimensional hand assembly.
      For Special Events fulfillment, we will help from preparing for an event - creating gift bags, literature folders - delivering and setting up at venues and pick up once event is done in the New York Metropolitan area.</p>
    </div>
  )
}
export default Work;