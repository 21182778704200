import React from 'react'

const Footer = () => {

  return (
    <div className='footer'>
      <p>Global Direct Fulfillment Inc. 2019 All Rights Reserved. <br />
      155 Route 303 Valley Cottage New York, 10989 </p>
      <p>(845) 268-5500</p>
    </div>
  )
}
export default Footer;