import React from 'react'

const FormHeader = () => {

  return (
    <div className='contact-info'>
      <ul>
        <li>Contact Information</li>
        <li>Cathy Kiernan</li>
        <li>Cathy@globaldirectfulfillment.com</li>
        <li>OPEN: MON-FRI: 9 AM- 5:30 PM</li>
      </ul>
      <ul>
        <li>Global Direct Fulfillment, Inc.</li>
        <li>155 Route 303</li>
        <li>Valley Cottage, NY 10989</li>
        <li>845-268-5500</li>
      </ul>

    </div>
  )
}
export default FormHeader;