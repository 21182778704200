import React, {Component} from "react"
//import TextArea from './TextArea'
import axios from 'axios'
//import FormHeader from './FormHeader'
//let returnMessage = '';

class ContactForm extends Component {
    constructor() {
        super()
        this.state = {
            firstName: "",
            lastName: "",
            address:"",
            address1:"",
            address2:"",
            city:"",
            state:"",
            zip:"",
            phone:"",
            evephone:"",
            email:"",
            comment:""
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
      event.preventDefault();
      let data = {
        firstName:this.state.firstName,
        lastName:this.state.lastName,
        comment:this.state.comment,
        city:this.state.city,
        state:this.state.state,
        zip:this.state.zip,
        phone:this.state.phone,
        evephone:this.state.evephone,
        address:this.state.address,
        address1:this.state.address1,
        address2:this.state.address2,
        email:this.state.email
      }
      console.log("Entering handleClick Data Object = ",data)

      // put data to db. So it can be access from the alexa app from dynamo db.
      // see function putToDb.js for details
      // axios.post('http://localhost:4000/facts', {
      //   firstName: data.firstName,
      //   lastName : data.lastName,
      //   comment : data.comment
      // })
      // .then(function (response) {
      //   const data = JSON.stringify(response.data)
      //   console.log("Data after post to server: ", data)
        // const content =`<h2>Welcome</h2><p>Your Email ${data.userName} has been recorded.</p>`
        // document.querySelector('#two').innerHTML = content
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });

    }

    // handlecomment(event) {
    //   this.setState({ [name] : value })
    // }

    handleChange(event) {
      const {name, value, type, checked} = event.target
      type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
  }
    
    render() {
        return (
          <div>
            <form 
            className='contact-form'
            action="mailto:marlene@globaldirectfulfillment.com"
            method="POST"
            encType="multipart/form-data"
            name="ContactForm">
              <fieldset>
                <legend> Call for a faster response </legend>
                <p></p>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <label>First Name
                          <input 
                          type="text" 
                          value={this.state.firstName} 
                          name="firstName"  
                          placeholder="First Name"                           
                          required
                          onChange={this.handleChange}
                          />
                        </label>
                      </td>
                      <td>
                        <label>Last Name 
                        <input 
                            type="text" 
                            value={this.state.lastName} 
                            name="lastName" 
                            placeholder="Last Name" 
                            onChange={this.handleChange}  
                            required
                        /></label>
                      </td>
                    </tr>
                    <tr>
                      <td><label>Address 
                        <input 
                            type="text" 
                            value={this.state.address} 
                            name="address" 
                            placeholder="Street / Suite #" 
                            onChange={this.handleChange} 
                        /></label>
                      </td>
                      <td>
                        <label>Line one
                        <input 
                            type="text" 
                            value={this.state.address1} 
                            name="address1" 
                            placeholder="Street" 
                            onChange={this.handleChange} 
                        /></label>
                      </td>
                      <td>
                        <label>Line two 
                        <input 
                            type="text" 
                            value={this.state.address2} 
                            name="address2" 
                            placeholder="Street" 
                            onChange={this.handleChange} 
                        /></label>
                      </td>
                    </tr>
                    <tr>
                      <td><label>City </label>
                        <input 
                            type="text" 
                            value={this.state.city} 
                            name="city" 
                            placeholder="City" 
                            onChange={this.handleChange} 
                        />        
                      </td>
                      <td><label>State </label>
                        <input 
                            type="select" 
                            value={this.state.state} 
                            name="state" 
                            placeholder="State" 
                            onChange={this.handleChange} 
                        /></td>
                      <td><label>Postal Code </label>
                        <input 
                            type="text" 
                            value={this.state.zip} 
                            name="zip" 
                            placeholder="zip" 
                            onChange={this.handleChange} 
                        />
                      </td>
                    </tr>
                    <tr>
                      <td><label>Phone </label>
                        <input 
                            type="text" 
                            value={this.state.phone} 
                            name="phone" 
                            placeholder="phone" 
                            onChange={this.handleChange} 
                        />
                      </td>
                      <td><label>Evening Phone </label>
                        <input 
                            type="text" 
                            value={this.state.evephone} 
                            name="evephone" 
                            placeholder="evening phone" 
                            onChange={this.handleChange} 
                        /></td>

                      <td><label>Email </label>
                        <input 
                            type="text" 
                            value={this.state.email} 
                            name="email" 
                            placeholder="Email" 
                            required
                            onChange={this.handleChange} 
                        /></td>
                    </tr>
                    <tr>
                      <td colSpan="3"> <label><h3>Leave a comment or message.</h3></label>
                        <textarea
                          name='comment'
                          rows={5}
                          cols = {80}
                          value={this.state.comment}
                          onChange={this.handleChange}
                          placeholder=""
                        /></td>
                    </tr>
                    <tr><td><button className='submit'>Submit</button></td></tr>
                    <tr><td colSpan="3">After submission of your contact information, our expert staff will get back to you as soon as possible with a free quote </td></tr>
                  </tbody>
                </table>                
              </fieldset>
            </form>
          </div>
        )
    }
}

export default ContactForm;