import React from 'react'

const Banner = () => {

  return (
    <div className='banner'>
      <p><span style={{fontSize:'36px'}}>Global Direct Fulfillment Inc.</span><br /><span style={{fontSize:'18px'}}> 155 Route 303 Valley Cottage New York 10989 : (845) 268-5500</span> </p>
    </div>
  )
}
export default Banner;