import React from 'react'

const CustomerProfiles = () => {

  return (
    <div className='CustomerProfiles'>
      {/* <div>
        <h3>Some of our very happy customers</h3>
      </div> */}
      <div>
        <ul>
          <li>Arnold Advertising/McDonalds</li>
          <li>Bank of America</li>
          <li>CBS Sports</li>
          <li>CBS Television Network</li>
          <li>Comedy Central</li>
          <li>Conde Nast Publications (UK)</li>
          <li>Hearst Publications</li>
          <li>KPMG</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>Lincoln Center Performing Arts</li>
          <li>MTV Networks</li>
          <li>Nickelodeon</li>
          <li>Out of the Blue/Super Why</li>
          <li>Provident Savings Bank</li>
          <li>Towers Perrin/Tilinghast</li>
          <li>Verizon</li>
          <li>Montgomery Outdoor Coat Co. (UK)</li>
        </ul>
      </div>
    </div>
  )
}
export default CustomerProfiles;