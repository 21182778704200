import React, {Component} from 'react'
import Header from './components/Header'
import Banner from './components/Banner'
//mport Nav from './components/Nav'
import MainContent from './components/MainContent'
import Services from './components/Services'
import Work from './components/Work'
import Footer from './components/Footer'
import ContactForm from './components/ContactForm'
import CustomerProfiles from './components/CustomerProfiles'
import FormHeader from './components/FormHeader'
import './App.css';

class App extends Component {
  render() {
    return (
      <div>
        <Banner />
        <Header />
        {/* <Nav /> */}
        <MainContent />
        <CustomerProfiles />
        <Work />
        <Services />        
        <FormHeader />
        <ContactForm />
        <Footer />
      </div>
    );
  }
}

export default App;
