import React from 'react'

const Services = () => {

  return (
    <div className="services">
      <ul>
        <li>Warehousing</li>
        <li>Shipping &amp; Logistics</li>
        <li>Internet Order Distribution</li>
        <li>Special Events Fulfillment</li>
        <li>Pick &amp; Pack/Order fulfillment&nbsp;</li>
        <li>Kitting &amp; Hand Assembly </li>
        <li>Promotional Fulfillment</li>
      </ul>
      <ul>
        <li>Transportation Management</li>
        <li>Break-Bulk Distribution</li>
        <li>Inventory Management</li>
        <li>Logistics Consulting</li>
        <li>Continuity Programs</li>
        <li>Reverse Logistics</li>
        <li>eCommerce Fulfillment</li>
      </ul>
    </div>
  )
}
export default Services;