import React from 'react'

const MainContent = () => {

  return (
    <div className='main-content'>
      <p  style={{textAlign:'center', fontSize:'1.4em'}} ><em>Tight deadlines and special orders are our Specialty!</em></p>
      <em><h3 style={{textAlign:'center', fontSize:'1.4em'}} >*No Long Term Contracts!* **Project by Project Invoicing!**</h3></em>
      <p style={{textIndent:'45px'}}> Global Direct&nbsp;is a family owned, full service, fulfillment company established in 1991. We pride ourselves in personal service and utmost customer satisfaction. Our dedicated and experienced staff offers a wide range of support services designed to ensure your satisfaction. Our seasoned approach ensures you the highest quality and most cost effective handling of all your projects, large and small. Just a handful of our happy and repeat customers:</p>        
    </div>
  )
}
export default MainContent;