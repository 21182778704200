import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import Header from './components/Header'
// import Banner from './components/Banner'
// import MainContent from './components/MainContent'
// import Footer from './components/Footer'
// import Nav from './components/Nav'
// import CustomerProfiles from './components/CustomerProfiles'
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('app'));
// ReactDOM.render(<Banner />, document.getElementById('banner'));
// ReactDOM.render(<Header />, document.getElementById('header'));
// ReactDOM.render(<Nav />, document.getElementById('navbar'));
// ReactDOM.render(<MainContent />, document.getElementById('main-content'));
// ReactDOM.render(<CustomerProfiles />, document.getElementById('CustomerProfiles'));
// ReactDOM.render(<Footer />, document.getElementById('footer'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
